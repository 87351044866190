<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="createDocument" title="Documento" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="createDocument" :clicked="createDocument" />

        <Button v-if="showBtnImport" _key="importDocument" title="Importar" classIcon="fa-solid fa-file-import"
          backGroundColor="#009183" color="white" size="small" :clicked="openImport" />

      </div>
    </ViewTemplateWithTable>

    <Modal title="Importar Modelo(s)" :width="550" :height="750" v-if="showModal('template')">
      <TemplateDocument />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import TemplateDocument from '../../../components/modules/adm/document/TemplateDocument.vue'

import { mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "DocumentListView",
  components: {
    ViewTemplateWithTable,
    Modal,
    Button, TemplateDocument
  },
  data() {
    return {
      panel: {
        module: "Adm",
        title: "Documento",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/adm/document/get-all",
        urlDeleteAllApi: "api/v1/adm/document/delete",
        headerTable: [
          {
            field: "identification",
            title: "Identificação",
            type: "link",
            routeName: "documentUpdate",
            iconSearch: true,
          },
          {
            field: "name",
            title: "Arquivo",
            type: "text",
            iconSearch: false,
          },
          {
            field: "modulePortuguese",
            title: "Módulo",
            type: "text",
            iconSearch: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("user", ["userLogged"]),
    showBtnImport() {
      let user = this.userLogged;
      if (user.user.userName == "UserTemp") return true;
      return false;
    },
  },
  methods: {
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    createDocument() {
      let self = this;
      setTimeout(function () {
        self.$router.push({
          name: "documentCreate",
        });
      }, 300);
    },
    openImport() {
      this.openModal("template");
      this.removeLoading(["importDocument"]);
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateDocument") {
          let self = this;
          setTimeout(function () {
            self.$router.push({
              name: "documentUpdate",
              params: { id: event.data.id },
            });
            self.removeLoading([event.data.id]);
          }, 300);
        }
      },
      deep: true,
    },
  },
};
</script>
