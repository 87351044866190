<template>
    <div>
        <b-tabs>
            <b-tab title="Principal">
                <Toggle title="Marcar todos" :color="'#009183'" v-model="all" :changed="selectAll" />
                <hr class="hr" />
                <ScrollBar :minHeight="330" :maxHeight="330">
                    <div>
                        <CheckboxGroup field="permission" :stacked="true" :initialValue="documents"
                            :markFormDirty="false" :options="options" v-model="documents" />
                    </div>
                </ScrollBar>
            </b-tab>
            <b-tab title="Zona de Perigo">
                <div class="div-danger">
                    <div class="danger-zone">
                        <i class="fa-sharp fa-solid fa-triangle-exclamation"></i> Zona de Perigo
                    </div>
                    <CheckboxSimple title="Aplicar em todas as contas" v-model="allAccounts" />
                </div>
            </b-tab>
        </b-tabs>
        <div class="div-alert">
            <Alert v-if="allAccounts" type="danger">
                <span>
                    Atenção, os <b>DOCUMENTOS SELECIONADOS</b> serão aplicados em <b>TODAS</b> as contas, esta ação é
                    irreversível...
                </span>
            </Alert>
        </div>

        <div class="text-center">
            <Button _key="btnCreate" :disabled="documents.length == 0" title="Importar"
                classIcon="fa-solid fa-file-import" type="primary" size="small" :clicked="create" />
        </div>
    </div>
</template>

<script>

import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapActions, mapMutations } from "vuex";

export default {
    name: "TemplateDocument",
    components: {
        CheckboxGroup,
        CheckboxSimple,
        Button,
        Toggle,
        ScrollBar,
        Alert
    },
    data() {
        return {
            urlCreateAll: "/api/v1/adm/document/create-all-template",
            documents: [],
            allAccounts: false,
            options: [
                { text: 'Contrato', value: 'contrato' },
                { text: 'Fatura', value: 'fatura' },
                { text: 'Manutenção - Cliente', value: 'manutencaoCliente' },
                { text: 'Manutenção - Fornecedor', value: 'manutencaoFornecedor' },
                { text: 'Ordem de Serviço', value: 'ordemServico' },
                { text: 'Proposta', value: 'propostaPadrao' },
                { text: 'Proposta Agrupada', value: 'propostaAgrupada' },
                { text: 'Recibo', value: 'recibo' },
                { text: 'Romaneio de Renovação', value: 'romaneioRenovacao' },
                { text: 'Romaneio de Retorno', value: 'romaneioRetorno' },
                { text: 'Romaneio de Saída', value: 'romaneioSaida' },
            ],
            all: false
        }
    },
    methods: {
        ...mapMutations("generic", ["hideModal", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        create() {
            let params = {
                url: this.urlCreateAll, obj: {
                    documents: this.documents,
                    allAccounts: this.allAccounts
                }
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.hideModal();
                }
                this.removeLoading(["btnCreate"]);
            });
        },
        selectAll() {
            if (!this.all) this.documents = [];
            if (this.all) {
                this.options.forEach(item => {
                    this.documents.push(item.value);
                });
            }
        }
    }
}

</script>
<style scoped>
.div-danger {
    margin-right: 15px;
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    border: 1px solid red;
    background-color: white;
    border-radius: 6px;
}

.danger-zone {
    font-size: 18px;
    color: red;
    margin-left: 5px;
    margin-bottom: 10px;
}

.div-alert {
    margin-top: 10px;
}
</style>